import {Component} from '@angular/core';
import {LocalStorageService} from '../../services/local-storage.service';
import {Auth0Service} from '../../services/auth0.service';

@Component({
    selector: 'om-admin-container',
    templateUrl: './admin-container.component.html',
    styleUrls: ['./admin-container.component.scss']
})
export class AdminContainerComponent {

    constructor(public auth: Auth0Service) {
    }

    public btnLogoutClicked(): void {
        LocalStorageService.clearAll();
        // Logout in Auth0
        this.auth.logout();
    }
}
