import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    EToastType,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService,
    ToastDataModel,
    ToastService
} from '@relayter/rubber-duck';
import {filter, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AmazonService} from '../../../../api/services/amazon.service';
import {EUploadStatus} from '../../../../models/response/upload.model';
import {Papa} from 'ngx-papaparse';
import {DropdownOption} from '../../../../models/dropdown-option.model';
import {RLFileTypeUtil} from '../../../../util/file-type.util';
import {ARApiError} from '@relayter/core';

const DELIMITERS = {
    COMMA: ','
};

export interface IUploadCsvDialogData {
    requirements: string[];
    teamId: string;
}

@Component({
    selector: 'om-upload-csv-dialog',
    templateUrl: './upload-csv-dialog.component.html',
    styleUrls: ['./upload-csv-dialog.component.scss']
})
export class UploadCsvDialogComponent implements OnInit, OnDestroy {
    public requirements: string[];
    public teamId: string;
    public file: File;
    public form: UntypedFormGroup;
    public headers: DropdownOption[] = [];
    private uploadSubscription: Subscription;

    public csvExtension = RLFileTypeUtil.EXTENSIONS.CSV;

    public saveButton: DialogButtonConfig;

    private onDestroySubject = new Subject<void>();

    constructor(private amazonService: AmazonService,
                private papa: Papa,
                private toastService: ToastService,
                private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) modalData: IUploadCsvDialogData ) {
        this.requirements = modalData.requirements;
        this.teamId = modalData.teamId;
    }

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
    }

    public initButtons(): void {
        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new DialogCustomContentActionModel(cancelButton);

        this.saveButton = new DialogButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const save = new DialogCustomContentActionModel(this.saveButton);

        cancel.observable.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.dialogCustomContentService.close());
        save.observable.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.uploadFile(this.file));

        this.dialogCustomContentService.setDialogActions([cancel, save]);
    }

    public initForm(): void {
        this.form = new UntypedFormGroup({
            apiKey: new UntypedFormControl('', Validators.required),
            identifier: new UntypedFormControl('', Validators.required),
            withDeletion: new UntypedFormControl(false)
        });

        this.form.statusChanges.pipe(
            map((status) => status === 'VALID')
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public onFileChanged(file: File): void {
        if (!file) {
            return;
        }

        this.file = file;
        const extension = RLFileTypeUtil.getExtensionFromFileName(this.file.name);
        if (extension !== this.csvExtension) {
            return this.toastService.show(new ToastDataModel(EToastType.WARNING, `Please provide a file of the type ${this.csvExtension}`));
        }

        this.getHeadersFromCsvFile();
    }

    private getHeadersFromCsvFile(): void {
        this.headers = null;
        const config = {
            header: true,
            delimitersToGuess: Object.values(DELIMITERS),
            skipEmptyLines: true,
            error: (error) => this.toastService.show(new ToastDataModel(EToastType.ERROR, error.message)),
            complete: (results) => {
                this.headers = results.meta.fields.filter((field) => field !== '').map((field) => new DropdownOption(field, field));
            }
        };
        this.papa.parse(this.file, config);
    }

    private uploadFile(file: File): void {
        const upload = this.amazonService.createUpload(file, this.teamId);
        this.uploadSubscription = upload.progress$.pipe(
            filter((progress) => progress === EUploadStatus.Done || progress === EUploadStatus.Failed),
            withLatestFrom(upload.s3Key$)
        ).subscribe((data) => {
            this.saveButton.loading = false;
            if (data[0] === EUploadStatus.Failed) {
                return this.toastService.show(new ToastDataModel(EToastType.ERROR, 'Failed to upload file to Amazon'));
            }

            this.closeDialog(data[1]);
        }, (error: ARApiError) => {
            this.saveButton.loading = false;
            return this.toastService.show(new ToastDataModel(EToastType.ERROR, error.message));
        });
    }

    private closeDialog(s3Key: string): void {
        this.dialogCustomContentService.close({
            apiKey: this.form.value.apiKey,
            s3Key,
            identifier: this.form.value.identifier.getValue(),
            withDeletion: this.form.value.withDeletion
        });
    }
}
