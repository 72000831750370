export interface IQueryParam {
    queryParam: string;
    value: string | string[];
}

/**
 * Query parameters
 * Class to maintain request query parameters
 */
export class QueryParams {
    private params: object = {};

    /**
     * Set limit and offset parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {number} limit - Max number of results to return
     * @param {number} offset - Returns results from the offset
     * @returns {QueryParams}
     */
    public setLimitAndOffsetParams(limit?: number, offset?: number): QueryParams {
        if (limit && limit > 0 && limit <= 100) {
            this.params['limit'] = limit;
        }
        this.params['offset'] = offset && offset > 0 ? offset : 0;

        return this;
    }

    /**
     * Set sort field and sort direction parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} [sort] - The name of the field to sort on
     * @param {string} [sortDirection] - The direction of the sort (asc/desc)
     * @returns {QueryParams}
     */
    public setSortAndSortDirectionParams(sort?: string, sortDirection?: string): QueryParams {
        if (sort && sortDirection) {
            this.params['sort'] = sort;
            this.params['sortType'] = sortDirection.toUpperCase();
        }
        return this;
    }

    /**
     * Set value to search on. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} [search] = Value to search on within the results
     * @returns {QueryParams}
     */
    public setSearchParams(search?: string): QueryParams {
        if (search) {
            this.params['search'] = search;
        }
        return this;
    }

    /**
     * Add a query parameter by key and value. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} key - Name of parameter
     * @param {any} value - The value of the parameter
     * @returns {QueryParams}
     */
    public addParam(key: string, value: any): QueryParams {
        if (key && value) {
            this.params[key] = Array.isArray(value) ? value.join('|') : value;
        }
        return this;
    }

    /**
     * Add parameters by the keys of a object. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {object} object - Object to add to the query parameters
     * @returns {QueryParams}
     */
    public addObject(object: object): QueryParams {
        if (object) {
            for (const key of Object.keys(object)) {
                this.addParam(key, object[key]);
            }
        }
        return this;
    }

    /**
     * Get query parameters as an object
     * @returns {object}
     */
    public getParams(): object {
        return this.params;
    }
}
