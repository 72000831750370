import {NgModule} from '@angular/core';
import {RDModule} from '@relayter/rubber-duck';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DisableTeamFormComponent} from './disable-team/disable-team-form.component';
import {TransferOwnershipFormComponent} from './transfer-ownership-form/transfer-ownership-form.component';

@NgModule({
    declarations: [
        DisableTeamFormComponent,
        TransferOwnershipFormComponent
    ],
    imports: [
        CommonModule,
        RDModule,
        ReactiveFormsModule
    ],
    providers: [],
    exports: [
        DisableTeamFormComponent
    ]
})
export class OvermindFormsModule {
}
