import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

    constructor(private router: Router) {
    }

    // eslint-disable-next-line no-unused-vars
    public canActivate():
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (LocalStorageService.getAdminToken()) {
            return true;
        } else {
            this.router.navigate(['login']);
        }
    }
}
