import {NgModule} from '@angular/core';
import {TeamDetailComponent} from './team-detail/team-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {RDModule} from '@relayter/rubber-duck';
import {ApiKeyDialogComponent} from './team-detail/api-key-dialog/api-key-dialog.component';
import {UploadCsvDialogComponent} from './team-detail/upload-csv-dialog/upload-csv-dialog.component';
import {ComponentsModule} from '../../components/components.module';
import {NgChartsModule} from 'ng2-charts';
import {EditTeamUsageComponent} from './edit-team-usage/edit-team-usage.component';
import {NgxFilesizeModule} from 'ngx-filesize';

@NgModule({
    declarations: [
        TeamDetailComponent,
        EditTeamUsageComponent,
        ApiKeyDialogComponent,
        UploadCsvDialogComponent,
    ],
    imports: [
        CommonModule,
        RDModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        NgChartsModule,
        NgxFilesizeModule
    ]
})
export class TeamDetailModule {}
