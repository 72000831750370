import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {RoleModel} from '../../models/response/role.model';
import {SortDirection} from '@angular/material/sort';
import {QueryParams} from '../../classes/query-params';

@Injectable({providedIn: 'root'})
export class RoleService extends BaseApiRequestService {
    public getRoles(teamId: string, limit: number, offset = 0,
                    sortProperty?: string, sortOrder?: SortDirection, search = ''): Observable<ARPagedResponseDataModel<RoleModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);
        if (search) queryParams.addParam('search', search);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_PATH_ADMIN,
            ApiConstants.API_PATH_TEAMS,
            teamId,
            ApiConstants.API_GROUP_ROLES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, RoleModel);
        });
    }
}
