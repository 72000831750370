import {BehaviorSubject, Observable} from 'rxjs';

export class LocalStorageService {
    static readonly ADMIN_TOKEN_KEY: string = 'ADMIN_TOKEN_KEY';
    static readonly CLIENT_ID: string = 'CLIENT_ID';

    private static clientIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(LocalStorageService.getClientId());

    static getAdminToken(): string {
        return localStorage.getItem(LocalStorageService.ADMIN_TOKEN_KEY);
    }

    static setAdminToken(token: string): void {
        localStorage.setItem(LocalStorageService.ADMIN_TOKEN_KEY, token);
    }

    static setClientId(clientId: string): void {
        localStorage.setItem(LocalStorageService.CLIENT_ID, clientId);
        this.clientIdSubject.next(clientId);
    }

    static getClientId(): string {
        return localStorage.getItem(LocalStorageService.CLIENT_ID);
    }

    static getClientIdSubject(): Observable<string> {
        return this.clientIdSubject.asObservable();
    }

    static clearAll(): void {
      localStorage.clear();
    }
}
