<h4>Please make sure of the following before continuing</h4>
<ul>
    <li *ngFor="let requirement of requirements">{{requirement}}</li>
</ul>

<form [formGroup]="form">

    <nuc-form-field label="API Key">
        <nuc-input [formControl]="apiKey" placeholder="Please enter an API key"></nuc-input>
    </nuc-form-field>

</form>
