import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {UserModel} from '../../models/response/user.model';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';

@Injectable({providedIn: 'root'})
export class UserService extends BaseApiRequestService {
    public getUsers(teamId: string, excludeIds, limit: number, offset = 0, sortProperty?: string,
                    sortOrder?: SortDirection, search = ''): Observable<ARPagedResponseDataModel<UserModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);
        if (search) queryParams.addParam('search', search);
        queryParams.addParam('excludeIds', excludeIds);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_PATH_ADMIN,
            ApiConstants.API_PATH_TEAMS,
            teamId,
            ApiConstants.API_GROUP_USERS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, UserModel);
        });
    }
}
