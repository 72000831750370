import {Injectable} from '@angular/core';
import createAuth0Client, {getIdTokenClaimsOptions, GetTokenSilentlyOptions, IdToken, RedirectLoginResult} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {BehaviorSubject, forkJoin, from, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, concatMap, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LocalStorageService} from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class Auth0Service {
    private auth0Client$: Observable<Auth0Client>;

    // Create an observable of Auth0 instance of client
    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    private isAuthenticated$: Observable<boolean>;
    private handleRedirectCallback$: Observable<RedirectLoginResult>;
    // Create subject and public observable of user profile data
    private userProfileSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // Create a local property for login status
    public loggedIn: boolean = null;
    private clientId: string;
    private onResetSubject = new Subject<void>();

    constructor() {
        LocalStorageService.getClientIdSubject().subscribe((clientId) => this.resetClient(clientId));
    }

    /**
     * Reset Auth0 client, if necessary, on client id updates
     */
    private resetClient(clientId: string): void {
        if (this.clientId === clientId) {
            return;
        }
        this.clientId = clientId;
        this.onResetSubject.next();
        this.auth0Client$ = (from(
            createAuth0Client({
                domain: environment.AUTH0_DOMAIN,
                client_id: clientId,
                redirect_uri: `${window.location.origin}/callback`,
                audience: environment.AUTH0_AUDIENCE
            })
        ) as Observable<Auth0Client>).pipe(
            shareReplay(1), // Every subscription receives the same shared value
            catchError((error) => {
                return throwError(error);
            }),
            takeUntil(this.onResetSubject)
        );
        this.isAuthenticated$ = this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.isAuthenticated())),
            tap((res) => this.loggedIn = res),
            takeUntil(this.onResetSubject));
        this.handleRedirectCallback$ = this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
            takeUntil(this.onResetSubject)
        );
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    private getUser$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(
                client.getUser(options))
            ),
            tap(user => this.userProfileSubject.next(user))
        );
    }

    /**
     * Called on app initialization to set up local authentication streams
     */
    public localAuthSetup(): void {
        // This should only be called on app initialization
        // Set up local authentication streams
        this.isAuthenticated$.pipe(
                concatMap((loggedIn: boolean) => {
                    if (loggedIn) {
                        // If authenticated, get user and set in app
                        // NOTE: you could pass options here if needed
                        return this.getUser$({audience: environment.AUTH0_AUDIENCE});
                    }
                    // If not authenticated, return stream that emits 'false'
                    return of(loggedIn);
            })).subscribe((response: { [key: string]: any } | boolean) => {
                // If authenticated, response will be user object
                // If not authenticated, response will be 'false'
                this.loggedIn = !!response;
            });
    }

    /**
     * Login with redirect on Auth0
     */
    public login(redirectPath: string = '/'): void {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                redirect_uri: `${window.location.origin}/callback`,
                appState: {target: redirectPath},
                audience: environment.AUTH0_AUDIENCE,
                response_type: 'code'
            });
        });
    }

    /**
     * Handle authentication callbacks from Auth0
     */
    public handleAuthCallback(): Observable<[any, boolean]> {
        // Only the callback component should call this method
        // Call when app reloads after user logs in with Auth0
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let targetRoute: string; // Path to redirect to after login processed
        return this.handleRedirectCallback$.pipe(
            // Have client, now call method to handle auth0Service callback redirect
            tap(cbRes => {
                // Get and set target redirect route from callback results
                targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
            }),
            concatMap(() => {
                // Redirect callback complete; get user and login status
                return forkJoin([
                        this.getUser$({audience: environment.AUTH0_AUDIENCE}),
                        this.isAuthenticated$
                    ]);
            })
        );
    }

    /**
     * Logout on Auth0
     */
    public logout(err?: Error): void {
        if (this.loggedIn) {
            // Ensure Auth0 client instance exists
            this.auth0Client$.subscribe((client: Auth0Client) => {
                let returnTo = `${window.location.origin}/login`;
                if (err) {
                    returnTo += `?error=${encodeURIComponent(err.message)}`;
                }

                // Call method to log out
                client.logout({
                    client_id: this.clientId,
                    returnTo
                });
            });
        }
    }

    /**
     * Get authentication token from Auth0 without user interaction
     */
    public getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
        );
    }

    /**
     * Get id token from Auth0 without user interaction
     */
    public getIdTokenClaimsSilently$(options?: getIdTokenClaimsOptions): Observable<IdToken> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getIdTokenClaims(options)))
        );
    }
}
