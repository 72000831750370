import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export interface IApiKeyDialogData {
    requirements: string[];
}

@Component({
    selector: 'om-api-key-dialog',
    templateUrl: './api-key-dialog.component.html',
    styleUrls: ['./api-key-dialog.component.scss']
})
export class ApiKeyDialogComponent implements OnInit, OnDestroy {

    public requirements: string[];

    public apiKey = new UntypedFormControl('', Validators.required);
    public form = new UntypedFormGroup({
        apiKey: this.apiKey
    });
    private onDestroySubject = new Subject<void>();

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private modalData: IApiKeyDialogData ) {
        this.requirements = modalData.requirements;
    }

    public ngOnInit(): void {
        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new DialogCustomContentActionModel(cancelButton);

        const successButton = new DialogButtonConfig(BUTTON_TYPE.PRIMARY, 'Populate', false, false, true);
        const success = new DialogCustomContentActionModel(successButton);

        cancel.observable.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(() => this.dialogCustomContentService.close());

        success.observable.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(() => this.dialogCustomContentService.close(this.apiKey.value));

        this.dialogCustomContentService.setDialogActions([cancel, success]);

        this.form.statusChanges.pipe(
            map((status) => status === 'VALID')
        ).subscribe((valid) => successButton.disabled = !valid);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
    }

}
