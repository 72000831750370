import {Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, combineLatest, Subject} from 'rxjs';
import {SettingsStorageService} from '../../api/services/settings-storage.service';
import {AppConstants} from '../../app.constants';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';

export interface IPaginator {
    pageIndex: number;
    pageSize: number;
}

@Injectable()
export class PaginatorService implements OnDestroy {
    private static readonly LOCAL_STORAGE_KEY_PAGE_SIZE: string = 'page_size';

    private onDestroySubject = new Subject<void>();

    private pageIndex = new ReplaySubject<{viewId: string; pageIndex: number}>(2);
    public pageIndex$ = this.pageIndex.asObservable();

    private pageSize = new ReplaySubject<{viewId: string; pageSize: number}>(1);
    public pageSize$ = this.pageSize.asObservable();

    private subjects = [this.pageIndex, this.pageSize];

    constructor(private settingsStorageService: SettingsStorageService) {}

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public setPageIndex(viewId: string, pageIndex: number): void {
        this.pageIndex.next({viewId, pageIndex});
    }

    public setPageSize(viewId: string, pageSize: number): void {
        this.pageSize.next({viewId, pageSize});
    }

    public getPageIndex(viewId: string): Observable<number> {
        return this.pageIndex$.pipe(
            filter((res) => res.viewId === viewId),
            map((res) => res.pageIndex),
            takeUntil(this.onDestroySubject)
        );
    }

    private getPageSize(viewId: string): Observable<number> {
        return this.pageSize$.pipe(
            filter((res) => res.viewId === viewId),
            map((res) => res.pageSize),
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        );
    }

    public getStoredPageSize(viewId: string): Observable<number> {
        return new Observable((obs) => {
            const storedPageSizeConfig = this.settingsStorageService.loadSettings(PaginatorService.LOCAL_STORAGE_KEY_PAGE_SIZE);

            const pageSize = !storedPageSizeConfig || !storedPageSizeConfig[viewId]
                ? AppConstants.PAGE_SIZE_DEFAULT
                : storedPageSizeConfig[viewId];

            this.setPageSize(viewId, pageSize);
            obs.next(pageSize);
            obs.complete();
        });
    }

    public storePageSize(viewId: string, pageSize: number): void {
        let storedPageSizeConfig = this.settingsStorageService.loadSettings(PaginatorService.LOCAL_STORAGE_KEY_PAGE_SIZE);

        if (!storedPageSizeConfig) {
            storedPageSizeConfig = {};
        }

        storedPageSizeConfig[viewId] = pageSize;
        this.settingsStorageService.storeSettings(PaginatorService.LOCAL_STORAGE_KEY_PAGE_SIZE, storedPageSizeConfig);
    }

    public getPagination(viewId: string): Observable<{ pageIndex: number; pageSize: number }> {
        return combineLatest([this.getPageIndex(viewId), this.getPageSize(viewId)]).pipe(
            map((res) => {
                return {pageIndex: res[0], pageSize: res[1]};
            }),
            debounceTime(50), // Prevent emitting values after setting pageIndex and pageSize after each other
            distinctUntilChanged((prev, curr) =>
                (prev.pageIndex === curr.pageIndex && curr.pageIndex !== 1) && prev.pageSize === curr.pageSize),
            takeUntil(this.onDestroySubject));
    }
}
