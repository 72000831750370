import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {EditTeamUsageComponent, IEditTeamUsageData} from '../edit-team-usage/edit-team-usage.component';
import {TeamModel} from '../../../models/response/team.model';
import {TeamService} from '../../../api/services/team.service';
import {StatisticsService} from '../../../api/services/statistics.service';
import {filter, switchMap} from 'rxjs/operators';
import {JobService} from '../../../api/services/job.service';
import {ApiKeyDialogComponent, IApiKeyDialogData} from './api-key-dialog/api-key-dialog.component';
import {
    DialogCustomContentConfig,
    EToastType,
    FullModalConfig,
    FullModalService,
    NucDialogCustomContentService,
    ToastDataModel,
    ToastService
} from '@relayter/rubber-duck';
import assetBody from './asset-data';
import {ChannelService} from '../../../api/services/channel.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ChannelModel} from '../../../models/response/channel.model';
import {IUploadCsvDialogData, UploadCsvDialogComponent} from './upload-csv-dialog/upload-csv-dialog.component';
import {AmazonService} from '../../../api/services/amazon.service';
import {AppConstants} from '../../../app.constants';
import {ChartData, ChartOptions, ChartType} from 'chart.js';
import {DropdownOption} from '../../../models/dropdown-option.model';
import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {BaseChartDirective} from 'ng2-charts';
import {RLDatePipe} from '../../../pipes/date.pipe';
import {TransferOwnershipFormComponent} from '../../../forms/transfer-ownership-form/transfer-ownership-form.component';
import {Subscription} from 'rxjs';

export class StatisticsForm {
    period: FormControl<DropdownOption>;
    collection: FormControl<DropdownOption>;
}

@Component({
    selector: 'om-team-detail',
    templateUrl: './team-detail.component.html',
    styleUrls: ['./team-detail.component.scss']
})
export class TeamDetailComponent implements OnInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public team: TeamModel;
    public dateFormats = RLDatePipe.dateFormats;

    public barChartOptions: ChartOptions = {
        responsive: false,
        maintainAspectRatio: false,
        animation: {duration: 0}
    };
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartData: ChartData = {
        datasets: [{
            data: [],
            label: 'Total material created',
            backgroundColor: AppConstants.FIRST_BRAND_COLOR,
            hoverBackgroundColor: AppConstants.FIRST_BRAND_COLOR
        }]
    };
    public periods: DropdownOption[] = Object.values(StatisticsService.EStatisticsPeriod)
        .map((period) => new DropdownOption(period, period));
    public collections: DropdownOption[] = Object.values(StatisticsService.EStatisticsCollections)
        .map((collection) => new DropdownOption(collection, collection));
    public formGroupStatistics: UntypedFormGroup;

    private teamSubscription: Subscription;

    constructor(private route: ActivatedRoute,
                private teamService: TeamService,
                private fullModalService: FullModalService,
                private dialogService: NucDialogCustomContentService,
                private jobService: JobService,
                private integrationService: ChannelService,
                private toastService: ToastService,
                private amazonService: AmazonService,
                private statisticsService: StatisticsService,
                private dialogCustomContentService: NucDialogCustomContentService) {
    }

    public ngOnInit(): void {
        this.getTeam();

        this.formGroupStatistics = new FormGroup<StatisticsForm>({
            period: new FormControl(null),
            collection: new FormControl(null)
        });

        this.formGroupStatistics.valueChanges.subscribe((value: {
            period: DropdownOption;
            collection: DropdownOption
        }) => {
            this.getStatistics(value.period.getValue(), value.collection.getValue());
        });

        this.formGroupStatistics.patchValue({
            period: new DropdownOption(StatisticsService.EStatisticsPeriod.MONTH, StatisticsService.EStatisticsPeriod.MONTH),
            collection: new DropdownOption(StatisticsService.EStatisticsCollections.RLPublicationItem,
                StatisticsService.EStatisticsCollections.RLPublicationItem)
        });
    }

    private getStatistics(period, collection): void {
        const teamId = this.route.snapshot.params.teamId;
        this.statisticsService.getPeriodTotals(teamId, period, collection).subscribe(
            (results: any) => {
                this.barChartData.labels = results.items.map(item => item.getPeriod());
                this.barChartData.datasets[0].data = results.items.map(item => item.value);
                this.chart?.update();
            }
        );
    }

    public onEditTeamUsageClicked(): void {
        const config = new FullModalConfig('Team usage', 'Set up the team usage', {team: this.team} as IEditTeamUsageData);
        config.confirmClose = true;
        this.fullModalService.open(EditTeamUsageComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => this.getTeam());
    }

    private getTeam(): void {
        if (this.teamSubscription && !this.teamSubscription.closed) this.teamSubscription.unsubscribe();
        this.teamSubscription = this.teamService.getTeam(this.route.snapshot.params.teamId).subscribe((team) => this.team = team);
    }

    public populateProductsClicked(): void {
        const dialogData = {
            requirements: [
                `API key is for team: ${this.team.name} (${this.team._id})`,
                'Custom field SKU (string) exists for team'
            ]
        } as IApiKeyDialogData;

        const dialogConfig = new DialogCustomContentConfig(
            'Populate Products',
            `Are you sure you want to populate products for team: ${this.team.name}`,
            dialogData);

        const dialog = this.dialogService.open(ApiKeyDialogComponent, dialogConfig);
        dialog.afterClosed().subscribe((apiKey) => {
            if (apiKey) {
                this.postProductImportJob(apiKey, 'demo data/demo_products.csv', 'SKU');
            }
        });
    }

    public importCustomerProductDataClicked(): void {
        const dialogData = {
            requirements: [
                `API key is for team: ${this.team.name} (${this.team._id})`,
                'All data fields exists for the team'
            ],
            teamId: this.team._id
        } as IUploadCsvDialogData;

        const dialogConfig = new DialogCustomContentConfig(
            'Import product data',
            'You can upload a CSV file and import the product data to Relayter',
            dialogData);

        const dialog = this.dialogService.open(UploadCsvDialogComponent, dialogConfig);
        dialog.afterClosed().subscribe((values) => {
            if (values && values.apiKey) {
                this.postProductImportJob(values.apiKey, values.s3Key, values.identifier, values.withDeletion);
            }
        });
    }

    public populateAssetsClicked(): void {
        const dialogData = {
            requirements: [
                `API key is for team: ${this.team.name} (${this.team._id})`,
                'Custom field SKU (string) exists for team',
                'Products were successfully populated',
                `Google Drive 'Relayter/Assets/Demo material/Prefill/assets' folder was copied to S3 (/demo data/assets/)`
            ]
        } as IApiKeyDialogData;

        const dialogConfig = new DialogCustomContentConfig(
            'Populate Assets',
            `Are you sure you want to populate assets for team: ${this.team.name}`,
            dialogData);

        const dialog = this.dialogService.open(ApiKeyDialogComponent, dialogConfig);
        dialog.afterClosed().subscribe((apiKey) => {
            if (apiKey) {
                this.postAssetImportJob(apiKey);
            }
        });
    }

    public populateCampaignClicked(): void {
        const dialogData = {
            requirements: [
                `API key is for team: ${this.team.name} (${this.team._id})`,
                'Custom field SKU (string) exists for team',
                'Products were successfully populated'
            ]
        } as IApiKeyDialogData;

        const dialogConfig = new DialogCustomContentConfig(
            'Populate Campaign',
            `Are you sure you want to create a campaign for team: ${this.team.name}`,
            dialogData);

        const dialog = this.dialogService.open(ApiKeyDialogComponent, dialogConfig);
        dialog.afterClosed().subscribe((apiKey) => {
            if (apiKey) {
                this.postCampaignImportJob(apiKey);
            }
        });
    }

    public populateInDesignLibraryClicked(): void {
        const dialogData = {
            requirements: [
                `API key is for team: ${this.team.name} (${this.team._id})`,
                `The InDesign Library to populate is available on S3 at '${AppConstants.DEMO_INDESIGN_LIBRARY}'`,
                'The Demo Library does not already exist for the team'
            ]
        } as IApiKeyDialogData;

        const dialogConfig = new DialogCustomContentConfig(
            'Populate InDesign Library',
            `Are you sure you want to add the InDesign Library for team: ${this.team.name}`,
            dialogData);

        const dialog = this.dialogService.open(ApiKeyDialogComponent, dialogConfig);
        dialog.afterClosed().subscribe((apiKey) => {
            if (apiKey) {
                this.postInDesignLibraryJob(apiKey);
            }
        });
    }

    private postProductImportJob(apiKey: string, s3Key: string, identifier: string, withDeletion?: boolean): void {
        const jobData = {s3Key, identifier};
        const jobName = withDeletion ? 'PRODUCT_IMPORT_WITH_DELETION' : 'PRODUCT_IMPORT';

        this.jobService.postProductImportJob(jobName, jobData, apiKey)
            .subscribe(
                () => this.toastService.show(new ToastDataModel(EToastType.SUCCESS, `Scheduled ${jobName} job successfully`)),
                (error) => this.toastService.show(new ToastDataModel(EToastType.ERROR, error.message)));
    }

    private postAssetImportJob(apiKey: string): void {
        const jobData = assetBody;

        this.integrationService.getChannels(apiKey).pipe(
            switchMap((channels: ARPagedResponseDataModel<ChannelModel>) => {
                jobData.channelIds = channels.items.map((channel) => channel._id);
                return this.jobService.postImportJob('ADD_PRODUCT_ASSETS_NEW', jobData, apiKey);
            })
        ).subscribe(
            () => this.toastService.show(new ToastDataModel(EToastType.SUCCESS, 'Scheduled Asset import job successfully')),
            (error) => this.toastService.show(new ToastDataModel(EToastType.ERROR, error.message)));
    }

    private postCampaignImportJob(apiKey: string): void {
        const today = new Date();
        const jobData = {
            campaignName: 'Demo campaign',
            campaignStartDate: today,
            campaignEndDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14),
            s3Key: 'demo data/demo_campaign.csv',
            productIdentifier: 'SKU',
            linkAllProductAssets: true
        };

        this.jobService.postCampaignImportJob('CAMPAIGN_SOFT_IMPORT_JOB', jobData, apiKey)
            .subscribe(
                () => this.toastService.show(new ToastDataModel(EToastType.SUCCESS, 'Scheduled Campaign import job successfully')),
                (error) => this.toastService.show(new ToastDataModel(EToastType.ERROR, error.message)));
    }

    private postInDesignLibraryJob(apiKey: string): void {
        // First copy the demo indesign library to the teams temp location, then post the 'Create indesign library' job
        this.amazonService.copyToTempOnAmazon(AppConstants.DEMO_INDESIGN_LIBRARY, this.team._id)
            .pipe(
                switchMap((s3KeyModel) => {
                    const jobData = {
                        name: 'Demo Library',
                        s3Key: s3KeyModel.s3Key
                    };

                    return this.jobService.postInDesignibraryJob('CREATE_INDESIGN_LIBRARY', jobData, apiKey);
                })
            ).subscribe(
            () => this.toastService.show(new ToastDataModel(EToastType.SUCCESS, 'Scheduled InDesign Library job successfully')),
            (error) => this.toastService.show(new ToastDataModel(EToastType.ERROR, error.message))
        );
    }

    public openTransferOwnershipDialog(): void {
        const dialogConfig = new DialogCustomContentConfig('Transfer ownership', 'Select an existing user within the team to transfer the ownership to', {team: this.team});
        this.dialogCustomContentService.open(TransferOwnershipFormComponent, dialogConfig)
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe(() => this.getTeam());
    }
}
