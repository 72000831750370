import {Injectable} from '@angular/core';
import {
    ARApiError,
    ARApiRequestService,
    ARPagedResponseDataModel,
    ARPagedResponseModel,
    ARRequestOptions,
    ARResponseModel
} from '@relayter/core';
import {Deserialize, DeserializeInto} from 'cerialize';
import {Subscriber} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {ApiConstants} from '../api.constant';

@Injectable()
export class BaseApiRequestService extends ARApiRequestService {
    /**
     * Handle an error and logout if needed
     */
    public handleError(error: ARApiError, obs?: any): void {
        if (obs) {
            obs.error(error);
            obs.complete();
        }
    }

    /**
     * Handle the detail response of a single detail model
     */
    protected handleDetailResponse<T>(options: ARRequestOptions,
                                      obs: Subscriber<T>,
                                      // eslint-disable-next-line @typescript-eslint/ban-types
                                      model: Function,
                                      nullable = false): void {
        this.doRequest(options).subscribe(
            (res: ARResponseModel) => {
                if (nullable && Object.keys(res.data).length === 0) {
                    obs.next(null);
                } else {
                    obs.next(Deserialize(res.data, model));
                }
                obs.complete();
            },
            (err: ARApiError) => {
                this.handleError(err, obs);
            });
    }

    /**
     * Handle the paged response of a paged call and map the response to the preferred models
     */
    protected handlePagedResponse<T, Y = object>(options: ARRequestOptions,
                                                 obs: Subscriber<ARPagedResponseDataModel<T, Y>>,
                                                 // eslint-disable-next-line @typescript-eslint/ban-types
                                                 model: Function,
                                                 // eslint-disable-next-line @typescript-eslint/ban-types
                                                 metaDataModel?: Function): void {
        this.doPagedRequest(options).subscribe(
            (res: ARPagedResponseModel) => {
                const data: ARPagedResponseDataModel<T, Y> = new ARPagedResponseDataModel<T, Y>();
                data.total = res.data.total;
                DeserializeInto(res.data.items, model, data.items);

                if (res.data.metaData && metaDataModel) {
                    data.metaData = Deserialize(res.data.metaData, metaDataModel);
                }

                obs.next(data);
                obs.complete();
            },
            (err: ARApiError) => {
                // real app breaking error log to bugsnag?
                this.handleError(err, obs);
            });
    }

    /**
     * Handle No Error Response in this function to prevent duplicate code
     */
    protected handleNoErrorResponse(options: ARRequestOptions, obs: Subscriber<boolean>): void {

        this.doRequest(options).subscribe(
            () => {
                obs.next(true);
                obs.complete();
            },
            (err: ARApiError) => {
                this.handleError(err, obs);
            });
    }

    /**
     * Handle the array response and map the response to the preferred models
     */
    protected handleArrayResponse<T>(options: ARRequestOptions,
                                     obs: Subscriber<ARPagedResponseDataModel<T>>,
                                     // eslint-disable-next-line @typescript-eslint/ban-types
                                     model: Function): void {
        this.doRequest(options).subscribe(
            (res: ARPagedResponseModel) => {
                const data: ARPagedResponseDataModel<T> = new ARPagedResponseDataModel<T>();
                DeserializeInto(res.data, model, data.items);
                obs.next(data);
                obs.complete();
            },
            (err: ARApiError) => {
                this.handleError(err, obs);
            });
    }

    /**
     * Handle a response which is an array of strings (no paging and models)
     * @param options
     * @param obs
     * @protected
     */
    protected handleStringArrayResponse(options: ARRequestOptions, obs: Subscriber<string[]>): void {
        this.doRequest(options).subscribe(
            (res: ARPagedResponseModel<string>) => {
                obs.next(res.data.items);
                obs.complete();
            },
            (err: ARApiError) => {
                this.handleError(err, obs);
            });
    }

    protected appendHttpHeaders(teamId: string, headers = new HttpHeaders()): HttpHeaders {
        return headers.append(ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_TEAM_ID, teamId);
    }
}
