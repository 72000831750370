<form [formGroup]="formGroup">
    <nuc-form-field label="User limit">
        <nuc-input formControlName="userLimit" type="number" placeholder="Fill in the user limit"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="Storage limit">
        <nuc-input formControlName="storageLimit" type="number" placeholder="Fill in the storage limit"></nuc-input>
        <nuc-hint>{{formGroup.value.storageLimit | filesize}}</nuc-hint>
    </nuc-form-field>
    <nuc-form-field label="Publication item creation limit">
        <nuc-input formControlName="publicationItemLimit" type="number" placeholder="Fill in the publication item creation limit"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="InDesign generation limit">
        <nuc-input formControlName="indesignGenerationLimit" type="number" placeholder="Fill in the InDesign item creation limit"></nuc-input>
    </nuc-form-field>
    <ng-container formGroupName="contract">
        <nuc-form-field label="Contract period">
            <nuc-dropdown
                formControlName="period"
                placeholder="Choose the contract period"
                [items]="contractPeriodOptions"
                [nullOption]="false"
                [required]="true">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-form-field label="Contract starts at">
            <nuc-datepicker
                formControlName="startDate"
                placeholder="Choose the start date of the contract">
            </nuc-datepicker>
        </nuc-form-field>
    </ng-container>
</form>
