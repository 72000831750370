<div class="description">
    <p class="text-center grey"> You are about to disable {{team.name}} from Relayter, this means no one will be able to
        access to this team. Are your sure?</p>
    <br/>
    <p class="text-center grey">Fill in the team name to disable.</p>
</div>
<form [formGroup]="disableTeamForm" (ngSubmit)="onSubmit()">
    <nuc-form-field label="Team name">
        <nuc-input type="text" placeholder="Fill in team name to disable" formControlName="teamName" required>
        </nuc-input>
    </nuc-form-field>
    <div class="dialog-footer">
        <nuc-button-secondary class="auth0" (click)="onCancel()" text="Cancel"></nuc-button-secondary>
        <nuc-button-destructive [loading]="isLoading" type="submit" text="Disable"
                                [disabled]="team?.name !== disableTeamForm.value.teamName"></nuc-button-destructive>
    </div>
</form>
