<div class="container" ngSwitch="{{stateChanged | async}}">
    <div class="relayter-dark">
        <div class="relayter-dark-logo"></div>
    </div>

    <div class="content">
        <div *ngSwitchCase="ELoginState.INIT">
            <div class="hero text-center">Welcome to the Overmind</div>
            <div class="text-center grey">First things first, identify yourself</div>
            <div class="form-container">
                <form *ngIf="formGroup" [formGroup]="formGroup">
                    <nuc-form-field label="E-mail">
                        <nuc-input formControlName="email" placeholder="Enter email address" type="email" autofocus="true" required></nuc-input>
                    </nuc-form-field>
                    <div class="button-bar">
                        <nuc-button-secondary [loading]="loading" (click)="onSubmitValid()" [disabled]="!isFormGroupValid" type="submit" text="Next"></nuc-button-secondary>
                    </div>
                </form>
            </div>

        </div>

        <div *ngSwitchCase="ELoginState.SSO">
            <div class="hero text-center">Redirect to the SSO provider...</div>
        </div>
    </div>
</div>
