import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable, Subscriber} from 'rxjs';
import {AdminModel} from '../../models/response/AdminModel';
import {ARApiUrlBuilderService, ARRequestOptions, ARResponseModel} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {Auth0Service} from '../../services/auth0.service';
import {Deserialize} from 'cerialize';
import {LocalStorageService} from '../../services/local-storage.service';
import {TokenModel} from '../../models/response/TokenModel';
import {Auth0TokenModel} from '../../models/request/Auth0TokenModel';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BaseApiRequestService {

    constructor(private auth0: Auth0Service) {
        super();
    }

    public loginAuth0(tokens: Auth0TokenModel): Observable<TokenModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
                ApiConstants.API_BASE_PATH,
                ApiConstants.API_PATH_ADMIN,
                ApiConstants.API_PATH_EXCHANGE_AUTH0_ID_TOKEN]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        // Secure API calls with the signed a bearer access token from auth0
        options.headers = options.headers.set('Authorization', 'Bearer ' + tokens.accessToken);
        options.body = {
            grant_type: 'authorization_code',
            code: tokens.idToken,
            client_id: environment.OAUTH_CLIENT_ID
        };
        // Before login, clear all user authentication data
        LocalStorageService.clearAll();
        return new Observable<any>((obs) => {
            this.handleAdminLoginResponse(options, obs, TokenModel);
        });
    }

    /**
     * Logout user
     */
    private logOutUser(err?: Error): void {
        this.auth0.logout(err);
        LocalStorageService.clearAll();
    }

    /**
     * Handle the admin login response of the Admin Model or TokenModel
     */
    protected handleAdminLoginResponse(options: ARRequestOptions,
                                       obs: Subscriber<AdminModel | TokenModel>,
                                       // eslint-disable-next-line @typescript-eslint/ban-types
                                       model: Function): void {
        this.doRequest(options).subscribe(
            (res: ARResponseModel) => {
                if (res.data) {
                    const rlToken: AdminModel = Deserialize(res.data, model);

                    LocalStorageService.setAdminToken(rlToken.token);
                    // Announce new logged in user to user subject
                    obs.next(rlToken);
                } else {
                    this.logOutUser(new Error('Wrong credentials'));
                    obs.error(new Error('No admin token returned'));
                }
                obs.complete();
            },
            (err) => {
                this.logOutUser(err);
                // real app breaking error log to Bugsnag?
                obs.error(err);
            });
    }

}
