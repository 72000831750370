<h4>Please make sure of the following before continuing</h4>
<ul>
    <li class="grey" *ngFor="let requirement of requirements">{{requirement}}</li>
</ul>

<form [formGroup]="form">

    <nuc-form-field label="Upload file">
        <om-file-input inActiveTitle="Drop the CSV to upload"
                       (fileChanged)="onFileChanged($event)">
        </om-file-input>
    </nuc-form-field>

    <nuc-form-field label="API Key">
        <nuc-input formControlName="apiKey" placeholder="Enter an API key"></nuc-input>
    </nuc-form-field>

    <div *ngIf="headers?.length > 0">
        <nuc-form-field label="Identifier">
            <nuc-dropdown formControlName="identifier"
                          placeholder="Select an identifier"
                          [items]="headers">
            </nuc-dropdown>
        </nuc-form-field>
    </div>

    <nuc-checkbox text="With deletion" formControlName="withDeletion"></nuc-checkbox>
</form>
