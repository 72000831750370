import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class DropdownOption implements IDropdownItem<boolean | string | number> {

    constructor(private title: string, private value: boolean | string | number) {}

    public getTitle(): string {
        return this.title;
    }
    public getValue(): boolean | string | number {
        return this.value;
    }
}
