import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {AccountTypeModel} from '../../models/response/acount-type.model';

@Injectable({
    providedIn: 'root'
})
export class AccountTypeService extends BaseApiRequestService {
    /**
     * Get account type by the admin e-mail address
     */
    public getAccountTypeByEmail(email: string): Observable<AccountTypeModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_PATH_ADMIN,
            ApiConstants.API_GROUP_ACCOUNT_TYPES,
            ApiConstants.API_METHOD_EMAIL,
            email
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, AccountTypeModel);
        });
    }

}
