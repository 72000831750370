export class RLFileTypeUtil {

    public static EXTENSIONS = {
        JPG: '.jpg',
        JPEG: '.jpeg',
        JPE: '.jpe',
        PSD: '.psd',
        PSB: '.psb',
        PNG: '.png',
        PDF: '.pdf',
        TIFF: '.tiff',
        TIF: '.tif',
        EPS: '.eps',
        EPT: '.ept',
        IDML: '.idml',
        INDL: '.indl',
        GIF: '.gif',
        AI: '.ai',
        ZIP: '.zip',
        INDD: '.indd',
        INDT: '.indt',
        CSV: '.csv'
    };

    /**
     * Retrieves any extension information from a filename and lowercases it
     */
    public static getExtensionFromFileName(name: string): string | null {
        if (name) {
            const matchExtensionRegExp: RegExp = new RegExp(/(?:\.([^.]+))?$/);
            const extension = matchExtensionRegExp.exec(name)[1];
            return extension ? `.${extension.toLowerCase()}` : null;
        }
        return null;
    }
}
