import {autoserialize} from 'cerialize';

export class Auth0TokenModel {
    @autoserialize public accessToken: string;
    @autoserialize public idToken: string;
    constructor(accessToken, idToken) {
        this.accessToken = accessToken;
        this.idToken = idToken;
    }
}
