import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {PermissionModel} from '../../models/permission.model';
import {SortDirection} from '@angular/material/sort';
import {QueryParams} from '../../classes/query-params';

@Injectable({
    providedIn: 'root'
})
export class PermissionService extends BaseApiRequestService {
    public getPermissions(limit?: number,
                          offset?: number,
                          sortProperty?: string,
                          sortOrder?: SortDirection,
                          search?: string): Observable<ARPagedResponseDataModel<PermissionModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        if (search) queryParams.addParam('search', search);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_PATH_ADMIN,
            ApiConstants.API_GROUP_PERMISSIONS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, PermissionModel);
        });
    }
}
