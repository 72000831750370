import {autoserialize} from 'cerialize';

export class StatisticsPeriodTotalModel {
    @autoserialize public year: string;
    @autoserialize public month: string;
    @autoserialize public day: string;
    @autoserialize public value: number;

    public getPeriod(): string {
        let dateString = this.day ? this.day : '';
        if (this.month) { dateString = `${dateString} ${this.month}`; }
        if (this.year) { dateString = `${dateString} ${this.year}`; }
        return dateString;
    }
}
