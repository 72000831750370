import {ARApiError, ARLogger} from '@relayter/core';
import {EToastType, ToastActionModel, ToastDataModel, ToastService} from '@relayter/rubber-duck';

export enum TIMOUT_TYPES {
    LONG = 10000
}

export class ToastOptionModel {
    public timeout?: TIMOUT_TYPES;
    public action?: ToastActionModel;
}

export class Toaster {

    private static toastService: ToastService;

    /**
     * Send out an error toast with an optional title
     */
    public static error(message: string, title?: string, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            title = title || 'Error';
            const toastData = new ToastDataModel(EToastType.ERROR, title, message, null, options?.timeout);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * Handle an incoming ARApiError by mapping it's error code to
     * the corresponding localized message and send out a warning toast
     * The message is overwritable
     * @param {ARApiError} error
     * @param {string} message
     * @param {string} [title]
     */
    public static handleApiWarning(error: ARApiError, message?: string, title?: string): void {
        if (Toaster.toastService) {
            const toastData = new ToastDataModel(EToastType.WARNING, title || 'Warning', message || error.message);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * Handle an incoming ARApiError by mapping it's error code to
     * the corresponding localized message and send out a error toast
     * The message is overwritable
     * @param {ARApiError} error
     * @param {string} message
     * @param {string} [title]
     */
    public static handleApiError(error: ARApiError, message?: string, title?: string): void {
        if (Toaster.toastService) {
            const toastData = new ToastDataModel(EToastType.ERROR, title || 'Error', message || error.message);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * Send out a warning toast with an optional title
     */
    public static warn(message: string, title?: string, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            const toastData = new ToastDataModel(EToastType.WARNING, title || 'Warning', message, null, options?.timeout);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * Send out an error toast for not yet implemented features
     */
    public static notYetImplementedError(): void {
        if (Toaster.toastService) {
            const toastData = new ToastDataModel(
                EToastType.ERROR,
                'Not yet implemented',
                'TODO: This functionality has not yet been implemented');

            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * Send out a success toast with an optional title
     */
    public static success(message: string, title?: string, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            title = title || 'Success';
            const toastData = new ToastDataModel(EToastType.SUCCESS, title, message, null, options?.timeout);
            if (options?.action) {
                toastData.addAction(options?.action);
            }
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * General method to use toasts
     * @param toastData
     */
    public static openToast(toastData: ToastDataModel): void {
        if (Toaster.toastService) {
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error('Toaster: Could not show toast: Toaster not initialized.');
        }
    }

    /**
     * Initialize the Toaster
     */
    public static initialize(toastService: ToastService): void {
        Toaster.toastService = toastService;
    }

}
