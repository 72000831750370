import {autoserialize, autoserializeAs} from 'cerialize';
import {ARBaseResponseModel} from '@relayter/core';
import {AwsSignedUrlHeaderModel} from './aws-signed-header.model';

export class AwsSignedUrlModel extends ARBaseResponseModel {
    @autoserialize public signedRequest: string;
    @autoserialize public signedUrl: string;
    @autoserialize public mimeType: string;
    @autoserialize public s3Key: string;
    @autoserializeAs(AwsSignedUrlHeaderModel) public headers: AwsSignedUrlHeaderModel[] = [];
}
