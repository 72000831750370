import {Injectable} from '@angular/core';

@Injectable()
export class ApiConstants {
    public static readonly API_BASE_PATH: string = 'api';
    public static readonly API_PATH_TEAMS: string = 'teams';
    public static readonly API_PATH_TOKEN: string = 'token';
    public static readonly API_PATH_ADMIN: string = 'admin';
    public static readonly API_PATH_JOBS: string = 'jobs';

    public static readonly API_GROUP_SIGN_AWS_S3: string = 'sign-aws-s3';
    public static readonly API_GROUP_COPY_TEMP_AWS: string = 'copy-temp-aws';
    public static readonly API_GROUP_ACCOUNT_TYPES: string = 'account-types';
    public static readonly API_GROUP_PERMISSIONS: string = 'permissions';
    public static readonly API_GROUP_ROLES: string = 'roles';
    public static readonly API_GROUP_USERS: string = 'users';

    public static readonly API_METHOD_EMAIL: string = 'email';
    public static readonly API_METHOD_STATISTICS: string = 'statistics';
    public static readonly API_METHOD_TRANSFER_OWNERSHIP: string = 'transfer-ownership';

    public static readonly API_PATH_EXCHANGE_AUTH0_ID_TOKEN: string = 'exchange-auth0-id-token';
    public static readonly REQUEST_METHODS = {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        PATCH: 'PATCH',
        DELETE: 'DELETE'
    };
    public static readonly RELAYTER_API_HEADERS = {
        X_RELAYTER_API_KEY: 'x-relayter-api-key',
        X_RELAYTER_TEAM_ID: 'x-relayter-team-id',
        X_RELAYTER_AUTHORIZATION_TYPE: 'x-relayter-authorization-type',
        AUTHORIZATION: 'authorization'
    };
}
