export class AppConstants {
    public static readonly HOME_PAGE: string = '/teams';
    public static readonly DEMO_INDESIGN_LIBRARY: string = 'demo data/demo_library.indl';
    public static readonly DEFAULT_LOCALE = 'en-US';
    public static readonly LOCALSTORAGE_LOCALE: string = 'overmind.locale';

    public static readonly PAGE_SIZE_DEFAULT: number = 20;
    public static readonly PAGE_SIZE_MAX: number = 100;
    public static readonly PAGE_SIZE_OPTIONS: number[] = [AppConstants.PAGE_SIZE_DEFAULT, 60, AppConstants.PAGE_SIZE_MAX];
    public static readonly FIRST_BRAND_COLOR: string = '#3FBB87';
}
