import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {StatisticsPeriodTotalModel} from '../../models/response/statistics-period-total.model';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';

enum EStatisticsPeriod {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    DAY = 'DAY'
}

enum EStatisticsCollections {
    RLPublicationItem = 'RLPublicationItem',
    RLJob = 'RLJob',
    RLSession = 'RLSession',
    RLProduct = 'RLProduct',
    RLAsset = 'RLAsset',
    RLCampaignItem = 'RLCampaignItem',
    RLStickyNote = 'RLStickyNote',
    RLFile = 'RLFile',
}

@Injectable({
    providedIn: 'root'
})
export class StatisticsService extends BaseApiRequestService {
    static EStatisticsPeriod = EStatisticsPeriod;
    static EStatisticsCollections = EStatisticsCollections;

    /**
     * Get collection creation statistics grouped by period.
     */
    public getPeriodTotals(teamId: string, period: string, collection: string): Observable<ARPagedResponseDataModel<StatisticsPeriodTotalModel>> {
        const queryParams = new QueryParams();
        queryParams.addParam('period', period);
        queryParams.addParam('collection', collection);
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_STATISTICS
        ], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        options.headers = this.appendHttpHeaders(teamId);

        return new Observable((obs) => {
            this.handleArrayResponse(options, obs, StatisticsPeriodTotalModel);
        });
    }

}
