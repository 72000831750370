import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '../pages/login/login.component';
import {TeamsComponent} from '../pages/admin-dashboard/teams/teams.component';
import {AdminContainerComponent} from '../pages/admin-dashboard/admin-container.component';
import {AuthenticatedGuard} from './authenticated.guard';
import {CallbackComponent} from '../pages/callback/callback.component';
import {TeamDetailComponent} from '../modules/team-detail/team-detail/team-detail.component';
import {TeamPermissionsComponent} from '../pages/team-permissions/team-permissions.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: '',
        component: AdminContainerComponent,
        canActivate: [
            AuthenticatedGuard
        ],
        children: [
            {
                path: 'teams',
                component: TeamsComponent
            },
            {
                path: 'teams/:teamId',
                component: TeamDetailComponent
            },
            {
                path: 'teams/:teamId/permissions',
                component: TeamPermissionsComponent
            },
            {
                path: '',
                redirectTo: 'teams',
                pathMatch: 'full'
            }
        ]
    },
    {
        redirectTo: 'login',
        path: '**'
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(
            routes, {enableTracing: false}
        )
    ]
})
export class BaseRouterModule {
}
