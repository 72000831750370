import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class RoleModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public users: number;
    @autoserialize public number: number;
    @autoserialize public root: boolean;
    @autoserialize public permissions: string[];

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getTitle(): string {
        return this.name;
    }

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getValue(): string {
        return this._id;
    }
}
