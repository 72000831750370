import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSortModule} from '@angular/material/sort';
import {RDModule} from '@relayter/rubber-duck';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FileInputComponent} from './file-input/file-input.component';
import {GetDragDropTitlePipe} from '../pipes/get-drag-drop-title.pipe';
import {PaginatorComponent} from './paginator/paginator.component';
import {ButtonBarComponent} from './button-bar/button-bar.component';
import {StringTransformPipe} from '../pipes/string-transform.pipe';
import {RLDatePipe} from '../pipes/date.pipe';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    declarations: [
        FileInputComponent,
        GetDragDropTitlePipe,
        PaginatorComponent,
        ButtonBarComponent,
        StringTransformPipe,
        RLDatePipe
    ],
    exports: [
        FileInputComponent,
        GetDragDropTitlePipe,
        PaginatorComponent,
        ButtonBarComponent,
        StringTransformPipe,
        RLDatePipe
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        RDModule
    ]
})
export class ComponentsModule {
}
