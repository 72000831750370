<form [formGroup]="form">
    <nuc-form-field label="New owner">
        <nuc-dropdown formControlName="newOwner"
                      placeholder="Select a new owner"
                      [items]="users"
                      [limit]="pageSize"
                      [searchable]="true"
                      (requestData)="getUsers($event)"
                      [required]="true"
                      [total]="totalUsers">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="New role for: {{team.owner.fullName}}">
        <nuc-dropdown-multiselect formControlName="newRoles"
                                  placeholder="Select your new role(s)"
                                  [required]="true"
                                  [items]="roles">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <nuc-form-field label='Type "Confirm" to complete the transfer'>
        <nuc-input formControlName="confirmText"
                   placeholder="Type in the word"
                   [required]="true">
        </nuc-input>
    </nuc-form-field>
</form>
