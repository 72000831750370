import {Component, OnInit} from '@angular/core';
import {Auth0Service} from './services/auth0.service';

@Component({
    selector: 'om-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(private auth0: Auth0Service) {
    }

    public ngOnInit() {
        // In order to restore local authentication status after a refresh, we'll call the localAuthSetup() method when the app initializes.
        this.auth0.localAuthSetup();
    }
}
