import {PROJECT_VARIABLES} from './project_variables';
import {AppConstants} from '../app/app.constants';
const AUTH0_TENANT = 'relayter.eu';

export const environment = {
    production: false,
    API_SERVER: PROJECT_VARIABLES.api_server.DEVELOPMENT,
    AUTH0_AUDIENCE: 'https://development.relayter.com/api',
    AUTH0_TENANT,
    AUTH0_DOMAIN: `${AUTH0_TENANT}.auth0.com`,
    AUTH0_CLIENT_ID: '4YhgUTKNQS0SEuDVkH0EmHlHjMB4Raa7',
    DEFAULT_LOCALE: AppConstants.DEFAULT_LOCALE,
    OAUTH_CLIENT_ID: PROJECT_VARIABLES.oauth_client_id
};
