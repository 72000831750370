import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {EToastType, ToastDataModel, ToastService} from '@relayter/rubber-duck';
import {Auth0Service} from '../../services/auth0.service';
import {AccountTypeModel} from '../../models/response/acount-type.model';
import {AccountTypeService} from '../../api/services/account-type.service';
import {LocalStorageService} from '../../services/local-storage.service';

enum ELoginState {
    INIT = 'INIT',
    SSO = 'SSO'
}

@Component({
    selector: 'om-login-component',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

    public formGroup: UntypedFormGroup;
    public isFormGroupValid: boolean;
    public loading = false;
    public ELoginState = ELoginState;
    private email: string;
    private state: ELoginState;
    public stateChanged = new BehaviorSubject<ELoginState>(ELoginState.INIT);
    private onDestroySubject = new Subject<void>();

    constructor(private accountTypeService: AccountTypeService,
                private route: ActivatedRoute,
                private auth0Service: Auth0Service,
                private toastService: ToastService) {
    }

    /**
     * Handle errors and subscribe to state changes and init the forms
     */
    public ngOnInit(): void {
        if (this.route.snapshot.queryParams.error) {
            this.toastService.show(new ToastDataModel(
                EToastType.ERROR,
                'Login failed',
                this.route.snapshot.queryParams.error));
        }

        this.stateChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((state: ELoginState) => {
            this.state = state;
        });

        this.initForms();
    }

    /**
     * Post on destroy on the subject
     */
    public ngOnDestroy(): void {
        this.onDestroySubject.next();
    }

    /**
     * Init forms
     */
    private initForms(): void {
        // Set up form groups for email and password
        this.formGroup = new UntypedFormGroup({
            email: new UntypedFormControl('')
        });

        // Observe changes
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === 'VALID'),
            takeUntil(this.onDestroySubject)
        ).subscribe((isValid: boolean) => this.isFormGroupValid = isValid);
    }

    /**
     * Submit action. ELoginState. SSO is handled outside this form (redirect)
     */
    public onSubmitValid(): void {
        if (this.state === ELoginState.INIT) {
            this.getAccountTypeAndGoToNext();
        } else {
            this.toastService.show(new ToastDataModel(
                EToastType.WARNING,
                'Not yet implemented',
                'TODO: This functionality has not yet been implemented'));
        }
    }

    /**
     * Get account type and go to next
     */
    private getAccountTypeAndGoToNext(): void {
        this.email = this.formGroup.value.email;
        this.loading = true;
        this.accountTypeService.getAccountTypeByEmail(this.email).subscribe(
            (accountType: AccountTypeModel) => {
                this.loading = false;
                if (accountType.loginFlow === 'SSO') {
                    this.stateChanged.next(ELoginState.SSO);
                    this.handleSSO(accountType);
                } else {
                    this.toastService.show(new ToastDataModel(
                        EToastType.WARNING,
                        'Not yet implemented',
                        'TODO: This functionality has not yet been implemented'));
                }
            },
            () => {
                this.loading = false;
                this.toastService.show(new ToastDataModel(
                    EToastType.WARNING,
                    'Not yet implemented',
                    'TODO: This functionality has not yet been implemented'));
            }
        );
    }

    /**
     * Handle SSO
     */
    private handleSSO(accountType: AccountTypeModel): void {
        LocalStorageService.setClientId(accountType.clientId);
        this.auth0Service.login();
    }
}
