import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'getDragDropTitle'})
export class GetDragDropTitlePipe implements PipeTransform {

    private static readonly DRAG_TITLE = 'Now drop it';

    public transform(isDragging: boolean, inActiveTitle: string): string {
        return isDragging ? GetDragDropTitlePipe.DRAG_TITLE : inActiveTitle;
    }
}
