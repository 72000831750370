<input type="file"
       (change)="onFilesChanged($event);"
       (dragenter)="isDragging = true"
       (dragleave)="isDragging = false"
       (dragend)="isDragging = false"/>
<label class="sub-label">
    <div class="title">
        <i class="nucicon_upload"></i>
        <p class="grey">{{isDragging | getDragDropTitle: inActiveTitle}}</p>
    </div>
    <nuc-button-secondary text="Or choose a file..."></nuc-button-secondary>
</label>
