import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class UserModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public fullName: string;
    @autoserialize public email: string;
    @autoserialize public acceptPolicy: boolean;
    @autoserialize public acceptTracking: boolean;

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getTitle(): string {
        return this.fullName + ' - ' + this.email;
    }

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getValue(): string {
        return this._id;
    }
}
