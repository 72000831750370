import {Deserialize, INewable, ISerializable, Serialize} from 'cerialize';
import {Injectable} from '@angular/core';
import {ARLogger} from '@relayter/core';

@Injectable({
    providedIn: 'root'
})
export class SettingsStorageService {
    private readonly prefixStorageKey = 'relayter-overmind';

    private static tryParseJson(jsonString: string): Record<string, any> | void {
        let jsonObject;
        try {
            jsonObject = JSON.parse(jsonString);
        } catch (err) {
            ARLogger.error(`Handled JSON parse error: ${err.name}: ${err.message}`);
        }
        return jsonObject;
    }

    private getStorageKey(key: string): string {
        return `${this.prefixStorageKey}.${key}`;
    }

    /**
     * Store settings
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    public storeSettings<T>(key: string, data: T, type?: Function | INewable<T> | ISerializable): void {
        const userKey = this.getStorageKey(key);

        const jsonObject = type ? Serialize(data, type) : data;
        localStorage.setItem(userKey, JSON.stringify(jsonObject));
    }

    /**
     * Load settings
     * Optional to deserialize to a specific type
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    public loadSettings<T>(key: string, type?: Function | INewable<T> | ISerializable): any {
        const userKey = this.getStorageKey(key);

        const jsonObject = SettingsStorageService.tryParseJson(localStorage.getItem(userKey));
        if (type) {
            return Deserialize(jsonObject, type);
        }

        return jsonObject;
    }
}
