import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ChannelModel} from '../../models/response/channel.model';
import {BaseApiRequestService} from './base-api-request.service';
import {ApiConstants} from '../api.constant';
import {environment} from '../../../environments/environment';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ChannelService extends BaseApiRequestService {

    public static readonly CHANNELS_PATH = 'channels';


    public getChannels(apiKey: string): Observable<ARPagedResponseDataModel<ChannelModel>> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ChannelService.CHANNELS_PATH]);


        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        options.headers = new HttpHeaders({[ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_API_KEY]: apiKey});

        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, ChannelModel);
        });
    }

}
