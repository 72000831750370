import {Component, OnInit} from '@angular/core';
import {Auth0Service} from '../../services/auth0.service';
import {exhaustMap, switchMap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {Auth0TokenModel} from '../../models/request/Auth0TokenModel';
import {Router} from '@angular/router';
import {AppConstants} from '../../app.constants';
import {AuthenticationService} from '../../api/services/authentication.service';
import {IdToken} from '@auth0/auth0-spa-js';

@Component({
    selector: 'om-callback',
    templateUrl: './callback.component.html'
})
/**
 * This page is called as callback from Auth0 after a user's authorization attempt
 */
export class CallbackComponent implements OnInit {

    constructor(private auth0Service: Auth0Service,
                private authenticationService: AuthenticationService,
                private router: Router) {}

    /**
     * On init
     */
    public ngOnInit(): void {
        // Handle Auth0 callback calls
        this.auth0Service.handleAuthCallback().pipe(
            exhaustMap(() => {
                return forkJoin({
                    accessToken: this.auth0Service.getTokenSilently$(),
                    claims: this.auth0Service.getIdTokenClaimsSilently$()
                });
            }),
            switchMap((res: {accessToken: string; claims: IdToken}) => {
                const tokenModel = new Auth0TokenModel(res.accessToken, res.claims.__raw);
                return this.authenticationService.loginAuth0(tokenModel);
            })
        ).subscribe(
            () => this.router.navigate([AppConstants.HOME_PAGE])
        );
    }
}
