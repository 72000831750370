/**
 *  authentication.intercepter
 *  app-relator-web
 *  Created by borisvisser on 08/03/18.
 *  Copyright © 2018 Creative Media Network. All rights reserved.
 */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService} from '../services/local-storage.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ApiConstants} from './api.constant';
import {Injectable} from '@angular/core';

@Injectable()
export class JwtIntercepter implements HttpInterceptor {
    public AMAZON_AWS_REQUEST = 'amazonaws';
    public AUTH_ID_TOKEN = `/${ApiConstants.API_BASE_PATH}/${ApiConstants.API_PATH_ADMIN}/${ApiConstants.API_PATH_EXCHANGE_AUTH0_ID_TOKEN}`;

    constructor(private router: Router) {

    }

    /**
     * Add the jwt token to the request
     */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
        // for the token exchange call, leave incoming headers untouched, TODO: find better solution?
        const isException = req.url.includes(this.AMAZON_AWS_REQUEST) || req.url.includes(this.AUTH_ID_TOKEN);
        if (isException) {
            return next.handle(req);
        }

        if (req.headers.has(ApiConstants.RELAYTER_API_HEADERS.AUTHORIZATION) &&
            req.headers.has(ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_AUTHORIZATION_TYPE)) {
            return next.handle(req);
        }

        const request = req.clone({
            setHeaders: {
                [ApiConstants.RELAYTER_API_HEADERS.AUTHORIZATION]: `${LocalStorageService.getAdminToken()}`,
                [ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_AUTHORIZATION_TYPE]: 'admin',
            }
        });

        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    LocalStorageService.clearAll();
                    this.router.navigate(['login']);
                }
                throw error;
            }));
    }
}
