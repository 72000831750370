import {autoserialize} from 'cerialize';

export enum EJobStatus {
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

export class JobModel {
    @autoserialize public _id: string;
    @autoserialize public status: EJobStatus;
    @autoserialize public type: string;
    @autoserialize public result: string;
}
