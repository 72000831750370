import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';

export class PermissionModel implements ITableItem {
    @autoserializeAs('key') public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserialize public active: boolean;

    constructor(_id: string, name?: string) {
        this._id = _id;
        this.name = name ? name : _id;
    }

}
