import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {RDModule} from '@relayter/rubber-duck';
import {TeamsComponent} from './admin-dashboard/teams/teams.component';
import {AdminContainerComponent} from './admin-dashboard/admin-container.component';
import {RouterModule} from '@angular/router';
import {OvermindFormsModule} from '../forms/overmind-forms.module';
import {ComponentsModule} from '../components/components.module';
import {CallbackComponent} from './callback/callback.component';
import {TeamDetailModule} from '../modules/team-detail/team-detail.module';
import {ReactiveFormsModule} from '@angular/forms';
import {TeamPermissionsComponent} from './team-permissions/team-permissions.component';

@NgModule({
    declarations: [
        LoginComponent,
        TeamsComponent,
        TeamPermissionsComponent,
        AdminContainerComponent,
        CallbackComponent
    ],
    imports: [
        CommonModule,
        RDModule,
        RouterModule,
        OvermindFormsModule,
        ComponentsModule,
        TeamDetailModule,
        ReactiveFormsModule
    ]
})
export class PagesModule {
}
