import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PaginatorService} from './paginator.service';

export interface IPaginationEvent {
    pageSize: number;
    pageIndex: number;
}

@Component({
    selector: 'om-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public viewId: string;

    @Input() public pageSizeOptions: DropdownItem<number>[] = AppConstants.PAGE_SIZE_OPTIONS
        .map((option) => new DropdownItem(`${option}`, option));

    @Input() public loading: boolean;
    @Input() public disableNextPage = false;
    @Input() public disablePageSizeOptions: boolean;

    public pageSize: number;
    public pageIndex: number = 0;

    public formGroup: UntypedFormGroup;
    private onDestroySubject = new Subject<void>();

    constructor(private paginatorService: PaginatorService) {
    }

    public ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            pageSize: new UntypedFormControl({value: null, disabled: this.isPageSizeDisabled()})
        });

        this.paginatorService.getStoredPageSize(this.viewId).subscribe((pageSize: number) => {
            this.pageSize = pageSize;
            const selectedPageSizeOption = this.pageSizeOptions.find((option) => option.getValue() === pageSize);
            this.formGroup.patchValue({pageSize: selectedPageSizeOption});
        });

        this.paginatorService.getPageIndex(this.viewId).subscribe((pageIndex) => this.pageIndex = pageIndex);

        this.formGroup.get('pageSize').valueChanges
            .pipe(
                filter((v) => !!v),
                map((option) => option.getValue()),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((pageSize: number) => {
                if (this.pageSize !== pageSize) {
                    this.pageSize = pageSize;
                    this.paginatorService.setPageIndex(this.viewId, 1); // reset pageIndex to 1 when pageSize changes
                    this.paginatorService.setPageSize(this.viewId, pageSize);
                    this.paginatorService.storePageSize(this.viewId, pageSize);
                }
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnChanges(): void {
        if (this.isPageSizeDisabled()) {
            this.formGroup?.get('pageSize').disable({emitEvent: false});
        } else {
            this.formGroup?.get('pageSize').enable({emitEvent: false});
        }
    }

    private isPageSizeDisabled(): boolean {
        return this.loading || this.disablePageSizeOptions;
    }

    public isNextPageDisabled(): boolean {
        return this.loading || this.disableNextPage;
    }

    public onArrowClick(value: 1 | -1): void {
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex + value);
    }
}
