export const PROJECT_VARIABLES = {
    environments: {
        LOCAL: 'local',
        DEVELOPMENT: 'development',
        DEVELOPMENT_CSD: 'development_csd',
        DEVELOPMENT_PD: 'development_pd',
        ACCEPTANCE: 'acceptance',
        PRODUCTION: 'production'
    },
    api_server: {
        LOCAL: 'http://localhost:5500',
        DEVELOPMENT: 'https://relayter-node-development.herokuapp.com',
        DEVELOPMENT_CSD: 'https://relayter-node-success.herokuapp.com',
        DEVELOPMENT_PD: 'https://relayter-node-development-pd.herokuapp.com',
        ACCEPTANCE: 'https://api-acceptance.relayter.com',
        PRODUCTION: 'https://api.relayter.com'
    },
    oauth_client_id: 'Rjq5B4os4B2DfR3nq199xVhwb9n4l8kE'
};
